<template>
  <v-container id="provider" fluid>
    <v-row>
      <v-col cols="12" md="2">
        <v-row class="ml-3">
          <h2>Provider</h2>
        </v-row>
        <v-card height="1000px">
          <v-row class="mx-1 mr-2">
            <v-col cols="12">
              <v-text-field
                v-model="searchProvider"
                label="Search..."
              ></v-text-field>
            </v-col>
          </v-row>
          <v-list two-line class="overflow">
            <v-list-item-group
              v-model="selectedProvider"
              active-class="primary--text"
            >
              <template>
                <v-list-item
                  v-for="(item, index) in providerItem"
                  :key="index"
                  :value="item.provider_id"
                >
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="10">
        <v-row class="ml-3">
          <v-col class="text-center">
            <h2>Provider Details</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="px-8">
            <v-row>
              <v-col cols="12" md="4">
                <span class="ml-4">Name</span>
                <v-subheader>
                  The service provider name that show on the system
                  list</v-subheader
                >
              </v-col>
              <v-col cols="12" md="8" class="px-7">
                <v-text-field
                  v-model="providerName"
                  :error-messages="providerNameErrors"
                  placeholder="e.g Alex Chandra"
                  clearable
                  required
                  @input="$v.providerName.$touch()"
                  @blur="$v.providerName.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <span class="ml-4">Role</span>
                <v-subheader>
                  The role description of the service provider
                </v-subheader>
              </v-col>
              <v-col cols="12" md="8" class="px-7">
                <v-text-field
                  v-model="providerRole"
                  placeholder="e.g Hair Designer"
                  clearable
                  :error-messages="providerRoleErrors"
                  required
                  @input="$v.providerRole.$touch()"
                  @blur="$v.providerRole.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <span class="ml-4">Contact</span>
                <v-subheader>
                  The service provider contact number that show on the
                  system</v-subheader
                >
              </v-col>
              <v-col cols="12" md="8" class="px-7">
                <v-text-field
                  v-model="providerPhone"
                  placeholder="e.g 0167148105"
                  clearable
                  :error-messages="providerPhoneErrors"
                  required
                  @input="$v.providerPhone.$touch()"
                  @blur="$v.providerPhone.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <span class="ml-4">Email</span>
                <v-subheader>
                  The provider email that show on the system</v-subheader
                >
              </v-col>
              <v-col cols="12" md="8" class="px-7">
                <v-text-field
                  v-model="providerEmail"
                  clearable
                  :error-messages="providerEmailErrors"
                  required
                  @input="$v.providerEmail.$touch()"
                  @blur="$v.providerEmail.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <span class="ml-4">Branch</span>
                <v-subheader
                  >Assign this provider to the specific branch for easy
                  management</v-subheader
                >
              </v-col>
              <v-col cols="12" md="7" class="px-7">
                <v-select
                  v-model="providerBranch"
                  :items="branchItem"
                  attach
                  dense
                  item-text="name"
                  item-value="branch_id"
                  :error-messages="providerBranchErrors"
                  required
                  @input="$v.providerBranch.$touch()"
                  @blur="$v.providerBranch.$touch()"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <span class="ml-4">Working day</span>
                <v-subheader>
                  Working day of service provider that can be overlapping with
                  the branch working day</v-subheader
                >
              </v-col>
              <v-col cols="12" md="7" class="px-7">
                <v-select
                  v-model="providerWorkingDay"
                  :items="day"
                  attach
                  chips
                  dense
                  multiple
                  :error-messages="providerWorkingDayErrors"
                  required
                  @input="$v.providerWorkingDay.$touch()"
                  @blur="$v.providerWorkingDay.$touch()"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <span class="ml-4">Working hour</span>
                <v-subheader
                  >Select the start time and end time for service provider
                  working hours
                </v-subheader>
              </v-col>
              <v-col cols="12" md="7" class="px-7">
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      ref="menu1"
                      v-model="startWorkingMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="providerStartWorkingTime"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="providerStartWorkingTimeErrors"
                          required
                          @input="$v.providerStartWorkingTime.$touch()"
                          @blur="$v.providerStartWorkingTime.$touch()"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="startWorkingMenu"
                        v-model="providerStartWorkingTime"
                        full-width
                        @click:minute="$refs.menu1.save(time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      ref="menu2"
                      v-model="endWorkingMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="providerEndWorkingTime"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="providerEndWorkingTimeErrors"
                          required
                          @input="$v.providerEndWorkingTime.$touch()"
                          @blur="$v.providerEndWorkingTime.$touch()"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="endWorkingMenu"
                        v-model="providerEndWorkingTime"
                        full-width
                        @click:minute="$refs.menu2.save(time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <span class="ml-4">Break Time</span>
                <v-subheader
                  >Choose the break time e.g. 12:00 - 13:00 for the service
                  provider
                </v-subheader>
              </v-col>
              <v-col cols="12" md="7" class="px-7">
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      ref="menu3"
                      v-model="startBreakMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="providerStartBreakTime"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="providerStartBreakTimeErrors"
                          required
                          @input="$v.providerStartBreakTime.$touch()"
                          @blur="$v.providerStartBreakTime.$touch()"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="startBreakMenu"
                        v-model="providerStartBreakTime"
                        full-width
                        @click:minute="$refs.menu3.save(time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      ref="menu4"
                      v-model="endBreakMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="providerEndBreakTime"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="providerEndBreakTimeErrors"
                          required
                          @input="$v.providerEndBreakTime.$touch()"
                          @blur="$v.providerEndBreakTime.$touch()"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="endBreakMenu"
                        v-model="providerEndBreakTime"
                        full-width
                        @click:minute="$refs.menu4.save(time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <span class="ml-4">slot</span>
                <v-subheader>
                  This is the slot for the customer to book appointment on this
                  service provider</v-subheader
                >
              </v-col>
              <v-col cols="12" md="2" class="px-7">
                <v-text-field
                  v-model="providerSlot"
                  suffix="Times"
                  :error-messages="providerSlotErrors"
                  required
                  @input="$v.providerSlot.$touch()"
                  @blur="$v.providerSlot.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <span class="ml-4">Status</span>
                <v-subheader
                  >Represent the provider status that in the branch
                </v-subheader>
              </v-col>
              <v-col cols="12" md="1" class="px-7">
                <v-switch v-model="providerStatus"></v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="text-center mr-n7">
          <v-col>
            <v-btn
              color="secondary"
              class="white--text"
              @click="addProvider()"
              v-if="!selectedProvider"
            >
              Add
            </v-btn>
            <v-btn
              color="secondary"
              class="white--text"
              @click="updateProvider()"
              v-if="selectedProvider"
            >
              Save
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click.stop="confirmDeleteDialog = true"
              v-if="selectedProvider"
            >
              Delete
            </v-btn>
            <v-dialog v-model="confirmDeleteDialog" max-width="290">
              <v-card>
                <v-card-title> Are you confirm to delete? </v-card-title>

                <v-card-text>
                  <span class="font-weight-medium"
                    >The selected provider and it's information will be
                    delete</span
                  >
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red darken-1"
                    text
                    @click="confirmDeleteDialog = false"
                  >
                    cancel
                  </v-btn>

                  <v-btn color="green darken-1" text @click="deleteProvider()">
                    Agree
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-snackbar v-model="snackbar" color="primary" :timeout="timeout">
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="secondary"
                  text
                  icon
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-snackbar>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import {
  required,
  numeric,
  minLength,
  maxLength,
  email,
  between,
} from "vuelidate/lib/validators";
export default {
  name: "Provider",

  mixins: [validationMixin],

  validations: {
    providerName: { required },
    providerRole: { required },
    providerPhone: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(11),
    },
    providerEmail: { required, email },
    providerBranch: { required },
    providerWorkingDay: { required },
    providerStartWorkingTime: { required },
    providerEndWorkingTime: { required },
    providerStartBreakTime: { required },
    providerEndBreakTime: { required },
    providerSlot: { required, numeric, between: between(1, 50) },
  },

  data: () => ({
    domain: BASEURL,
    company_id: "",
    providerName: "",
    providerRole: "",
    providerPhone: "",
    providerBranch: "",
    providerEmail: "",
    providerWorkingDay: [],
    providerStartWorkingTime: "",
    providerEndWorkingTime: "",
    providerStartBreakTime: "",
    providerEndBreakTime: "",
    providerSlot: "",
    providerStatus: "",
    startWorkingMenu: null,
    endWorkingMenu: null,
    startBreakMenu: null,
    endBreakMenu: null,
    searchProvider: "",
    selectedProvider: "",
    snackbar: false,
    timeout: 2000,
    day: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    time: "",
    providerItem: [],
    text: "",
    confirmDeleteDialog: false,
    branchItem: [],
    providerWorking: "",
  }),
  created() {
 
      var data = JSON.parse(this.getCookie("login"));
      this.company_id = data.company_id;
      this.getProviderByCompany();
      this.getCompanyBranch();
    
  },
  watch: {
    providerStatus() {
      this.changeStatusFormat();
    },
    selectedProvider() {
      this.getProviderDetail();
    },
  },
  computed: {
    providerNameErrors() {
      const errors = [];
      if (!this.$v.providerName.$dirty) return errors;
      !this.$v.providerName.required &&
        errors.push("Provider name is required");
      return errors;
    },
    providerRoleErrors() {
      const errors = [];
      if (!this.$v.providerRole.$dirty) return errors;
      !this.$v.providerRole.required &&
        errors.push("Provider role is required");
      return errors;
    },
    providerPhoneErrors() {
      const errors = [];
      if (!this.$v.providerPhone.$dirty) return errors;
      !this.$v.providerPhone.numeric &&
        errors.push("Phone must consist only number");
      !this.$v.providerPhone.minLength &&
        errors.push("Phone must at least 10 number");
      !this.$v.providerPhone.maxLength &&
        errors.push("Phone maxinmum consist of 11 number");
      !this.$v.providerPhone.required && errors.push("Contact is required");
      return errors;
    },
    providerEmailErrors() {
      const errors = [];
      if (!this.$v.providerEmail.$dirty) return errors;
      !this.$v.providerEmail.email && errors.push("Must be valid e-mail");
      !this.$v.providerEmail.required && errors.push("E-mail is required");
      return errors;
    },
    providerBranchErrors() {
      const errors = [];
      if (!this.$v.providerBranch.$dirty) return errors;
      !this.$v.providerBranch.required &&
        errors.push("Provider branch is empty");
      return errors;
    },
    providerWorkingDayErrors() {
      const errors = [];
      if (!this.$v.providerWorkingDay.$dirty) return errors;
      !this.$v.providerWorkingDay.required &&
        errors.push("Provider working day is empty");
      return errors;
    },

    providerStartWorkingTimeErrors() {
      const errors = [];
      if (!this.$v.providerStartWorkingTime.$dirty) return errors;
      !this.$v.providerStartWorkingTime.required &&
        errors.push("Please state the start working time of provider");
      return errors;
    },

    providerEndWorkingTimeErrors() {
      const errors = [];
      if (!this.$v.providerEndWorkingTime.$dirty) return errors;
      !this.$v.providerEndWorkingTime.required &&
        errors.push("Please state the end working time of provider");
      return errors;
    },

    providerStartBreakTimeErrors() {
      const errors = [];
      if (!this.$v.providerStartBreakTime.$dirty) return errors;
      !this.$v.providerStartBreakTime.required &&
        errors.push("Please state the start break time of provider");
      return errors;
    },

    providerEndBreakTimeErrors() {
      const errors = [];
      if (!this.$v.providerEndBreakTime.$dirty) return errors;
      !this.$v.providerEndBreakTime.required &&
        errors.push("Please state the end break time of provider");
      return errors;
    },
    providerSlotErrors() {
      const errors = [];
      if (!this.$v.providerSlot.$dirty) return errors;
      !this.$v.providerSlot.numeric &&
        errors.push("Slot must consist only number");
      !this.$v.providerSlot.between &&
        errors.push("Slot must be between 1 and 50");
      !this.$v.providerSlot.required && errors.push("Slot is required");
      return errors;
    },

    workingDayCheck() {
      var value = [];

      if (this.providerWorking[0] == 0) {
        value.push("Sunday");
      }
      if (this.providerWorking[1] == 0) {
        value.push("Monday");
      }
      if (this.providerWorking[2] == 0) {
        value.push("Tuesday");
      }
      if (this.providerWorking[3] == 0) {
        value.push("Wednesday");
      }
      if (this.providerWorking[4] == 0) {
        value.push("Thursday");
      }
      if (this.providerWorking[5] == 0) {
        value.push("Friday");
      }
      if (this.providerWorking[6] == 0) {
        value.push("Saturday");
      }

      return value;
    },
    realWorkingDay() {
      var day = [0, 0, 0, 0, 0, 0, 0];

      if (!this.providerWorkingDay.includes("Sunday")) {
        day[0] = 1;
      }
      if (!this.providerWorkingDay.includes("Monday")) {
        day[1] = 1;
      }
      if (!this.providerWorkingDay.includes("Tuesday")) {
        day[2] = 1;
      }
      if (!this.providerWorkingDay.includes("Wednesday")) {
        day[3] = 1;
      }
      if (!this.providerWorkingDay.includes("Thursday")) {
        day[4] = 1;
      }
      if (!this.providerWorkingDay.includes("Friday")) {
        day[5] = 1;
      }
      if (!this.providerWorkingDay.includes("Saturday")) {
        day[6] = 1;
      }

      return day;
    },
    realWorkingTime() {
      var time = [];

      time.push('"' + this.providerStartWorkingTime + '"');
      time.push('"' + this.providerEndWorkingTime + '"');

      return time;
    },
    realBreakTime() {
      var time = [];

      time.push('"' + this.providerStartBreakTime + '"');
      time.push('"' + this.providerEndBreakTime + '"');

      return time;
    },
  },

  methods: {
    changeStatusFormat() {
      if (this.providerStatus == true) {
        this.providerStatus = 1;
      } else {
        this.providerStatus = 0;
      }
    },
    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchItem = response.data.branch;
          } else {
            console.log("no branch find");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProviderByCompany() {
      const params = new URLSearchParams();
      params.append("getProviderByCompany", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/provider/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.providerItem = response.data.provider;
          } else {
            console.log("no provider find");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getProviderDetail() {
      this.$v.$reset();
      this.providerName = "";
      this.providerRole = "";
      this.providerPhone = "";
      this.providerEmail = "";
      this.providerBranch = "";
      this.providerWorkingTime = "";
      this.providerStartWorkingTime = "";
      this.providerEndWorkingTime = "";
      this.providerWorking = "";
      this.providerWorkingDay = "";
      this.providerSlot = "";
      this.providerStatus = 0;
      this.providerBreakTime = "";
      this.providerStartBreakTime = "";
      this.providerEndBreakTime = "";

      for (var i = 0; i < this.providerItem.length; i++) {
        if (this.providerItem[i].provider_id == this.selectedProvider) {
          this.providerName = this.providerItem[i].name;
          this.providerRole = this.providerItem[i].staff_description;
          this.providerPhone = this.providerItem[i].phone;
          this.providerEmail = this.providerItem[i].email;
          this.providerBranch = this.providerItem[i].branch_id;
          this.providerWorkingTime = JSON.parse(this.providerItem[i].work_time);
          this.providerStartWorkingTime = this.providerWorkingTime[0];
          this.providerEndWorkingTime = this.providerWorkingTime[1];
          this.providerWorking = JSON.parse(this.providerItem[i].work_day);
          this.providerWorkingDay = this.workingDayCheck;
          this.providerSlot = this.providerItem[i].slot;
          this.providerStatus = this.providerItem[i].status;
          this.providerBreakTime = JSON.parse(this.providerItem[i].break_time);
          this.providerStartBreakTime = this.providerBreakTime[0];
          this.providerEndBreakTime = this.providerBreakTime[1];
        }
      }
    },
    addProvider() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const params = new URLSearchParams();
        params.append("create", "done");
        params.append("branch_id", this.providerBranch);
        params.append("name", this.providerName);
        params.append("staff_description", this.providerRole);
        params.append("phone", this.providerPhone);
        params.append("email", this.providerEmail);
        params.append("work_day", "[" + this.realWorkingDay + "]");
        params.append("work_time", "[" + this.realWorkingTime + "]");
        params.append("slot", this.providerSlot);
        params.append("status", this.providerStatus);
        params.append("break_time", "[" + this.realBreakTime + "]");

        axios({
          method: "post",
          url: this.domain + "/provider/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.snackbar = true;
              this.text = "Provider add successfully";
              this.getProviderByCompany();
              this.$v.$reset();
              this.providerName = "";
              this.providerRole = "";
              this.providerPhone = "";
              this.providerEmail = "";
              this.providerBranch = "";
              this.providerWorkingTime = "";
              this.providerStartWorkingTime = "";
              this.providerEndWorkingTime = "";
              this.providerWorking = "";
              this.providerWorkingDay = "";
              this.providerSlot = "";
              this.providerStatus = 0;
              this.providerBreakTime = "";
              this.providerStartBreakTime = "";
              this.providerEndBreakTime = "";
            } else {
              this.snackbar = true;
              this.text = "Add Provider failed! Something went wrong";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    updateProvider() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const params = new URLSearchParams();
        params.append("update", "done");
        params.append("provider_id", this.selectedProvider);
        params.append("branch_id", this.providerBranch);
        params.append("name", this.providerName);
        params.append("staff_description", this.providerRole);
        params.append("phone", this.providerPhone);
        params.append("email", this.providerEmail);
        params.append("work_day", "[" + this.realWorkingDay + "]");
        params.append("work_time", "[" + this.realWorkingTime + "]");
        params.append("slot", this.providerSlot);
        params.append("status", this.providerStatus);
        params.append("break_time", "[" + this.realBreakTime + "]");

        axios({
          method: "post",
          url: this.domain + "/provider/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.snackbar = true;
              this.text = "Provider Update successfully";
              this.$v.$reset();
              this.getProviderByCompany();
            } else {
              this.snackbar = true;
              this.text = "Update failed! Something went wrong";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    deleteProvider() {
      const params = new URLSearchParams();
      params.append("delete", "done");
      params.append("provider_id", this.selectedProvider);
      axios({
        method: "post",
        url: this.domain + "/provider/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.confirmDeleteDialog = false;
            this.snackbar = true;
            this.text = "Provider delete successfully";
            this.getProviderByCompany();
          } else {
            this.confirmDeleteDialog = false;
            this.snackbar = true;
            this.text = "Delete Provider failed! Something went wrong";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  },
};
</script>

<style>
.v-text-field__details {
  margin-top: 5px;
}
</style>
